<template>
    <div class="faqroot" >
        <div class="faqHeader">
            <h2>Let's answer some of your questions</h2>
        </div>
        <v-row justify="center">
            <v-expansion-panels class="faqOuter">
                <v-expansion-panel
                        v-for="(item,i) in faqsub3"
                        :key="i"
                        style="margin-bottom: 8px; background-color: #000; color: white;border-radius: 15px;border-color: white;"
                >
                    <v-expansion-panel-header disable-icon-rotate style="border-radius: 15px;">
                        {{ item.header }}
                        <template v-slot:actions>
                            <v-icon color="white">
                                expand_more
                            </v-icon>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        {{item.description}}
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    </div>
</template>
<script>


    import faqsub from "@/components/reusable/faqsub";

    export default {
        name: "faqsub",
        components: {},
        data: () => ({

            faqs: [
                {id: 1, header: 'Generic Questions about Monoverse', description: '',class:'Generic'},
                {id: 2, header: 'Digital Assets Portfolio', description: '',class:'Digital'},
                {id: 3, header: 'Checkout Process', description: '',class:'Checkout'},
                {id: 4, header: 'Domain Parking', description: '',class:'Parking'},
            ],
            faqsub: [
                {id: 1, header: 'What is Monoverse?', description: 'An interconnected network of Premium Domain Names available for sale, along with the perpetual right to use Monoverse Digital Assets and Branding Toolkit.'},
                {id: 2, header: 'Where is Monoverse incorporated and operated?', description: 'Monoverse as a business, the Trademark, the Monoverse.com Domain, the Intellectual Property, the Technology, and the Business is owned and operated by Monoverse Capital Ltd, a UK Private Limited Company with ID 14372298, registered in 124 Cleveland Street, London.'},
                {id: 3, header: 'Is Monoverse a Cryptocurrency?', description: 'No. The Official and Trademarked Monoverse, hereby hosted in Monoverse.com, has no business interaction with blockchain and cryptos whatsoever.'},
                {id: 4, header: 'What are the Benefits of Joining Monoverse?', description: 'Domains are sold with the right to use Monoverse NFT-like-art Portfolio of Digital Assets, thus you save material resources on branding.\n' +
                        'Monoverse is giving you access to value added services offered by Monoversians.\n' +
                        'The value of Monoverse Domains is anticipated to increase over time.'},
                {id: 5, header: 'Can I Join Monoverse without acquiring a Domain Name?', description: 'Yes, you can, as long as you pay a Custom Branding Fee to use Monoverse Intellectual Property.\n' +
                        'In other words, you can seize the benefits of Monoverse for your existing business ownership.'},
                {id: 6, header: 'What is the process to buy this Domain Name?', description: 'Please “Continue to Checkout” via Monoverse.com and follow the instructions for the payment and transfer process.'},
                {id: 7, header: 'Do you offer Domain Transfers via a Trusted Third-Party Escrow Service?', description: 'Indeed, we do. While we support the use of Escrow.com as the most reliable service provider for Domain Transfers, we are happy to take the process with any Escrow Service of your choice.'},

            ],

            faqsub2: [
                {id: 1, header: 'When will I gain access to Monoverse Digital Asset and Branding Toolkit?', description: 'Following the completion of the Domain Transfer, we will send you a link to access Monoverse Portfolio of Digital Assets. In any case, we encourage direct interaction with Monoversians.'},
                {id: 2, header: 'What is the size and context of Monoverse Portfolio?', description: 'We encourage users to contact us for more information about the size, context, and use of branding toolkit, which is anyway evolving on a monthly basis to cover Monoversians’ needs.'},
                {id: 3, header: 'What is the annual license fee to use Monoverse Intellectual Property?', description: 'NONE. NIL. As soon as you by a Domain Name, you acquire the perpetual right to use Monoverse Digital Assets Portfolio for this specific domain.'},
                {id: 4, header: 'Is there a limitation in using Monoverse Digital Assets?', description: 'Essentially YES. The Right of Use is exclusively granted to the acquired Domain Name and Associated Business. However, we are happy to discuss any secondary business case or use in other domains.'},
                {id: 5, header: 'Is there geographical or market exclusivity offered?', description: 'We deem to establish a diversified network of users per location and industry, that will not be competitive to each other. To this extent, we are not bind to exclusivity in broad terms, but we assure that non-competition will apply at a regional level.'},
            ],
            faqsub3: [
                {id: 1, header: 'What is Stripe?', description: 'Stripe is an eligible, secure, US-based, Nasdaq-listed, and well acknowledged payment provider, used by thousands of companies around the world.'},
                {id: 2, header: 'What is the process when Checkout with Stripe is selected?', description: 'First things first, Stripe is an eligible, secure, and well acknowledged payment provider, used by thousands of companies around the world. By using Stripe, the whole process shall be completed without direct correspondence with Monoverse Help Desk.\n' +
                        'Step 1 : Pay online with a valid Debit or Credit Card.\n' +
                        'Step 2 : An EPP authorization code will be sent to your email within 24hours.\n' +
                        'Step 3 : Submit the EPP code in the “Transfer In” section of your Domain Registrar.\n' +
                        'Step 4 : Wait for another 24hours until we confirm the transfer.'},
                {id: 3, header: 'What is the difference between Stripe Checkout” and “Custom Checkout?', description: 'Simple. By using Stripe, the whole process shall be completed without direct correspondence with Monoverse Help Desk. By clicking Custom Checkout, you are reaching out to us, and we’ll take care of the rest.'},
                {id: 4, header: 'When should I use Custom Checkout via Monoverse Helpdesk?', description: 'A Custom Checkout is highly recommended for:Users with no experience in buying Domains\n' +
                        'Domain Names with a value above $20,000\n' +
                        'Anyone who feels more secured and comfier with human interaction\n' +
                        'When payment is made via bank transfer'},
                {id: 5, header: 'Can I pay with a bank transfer?', description: 'Absolutely. Kindly select a Custom Checkout via Monoverse Help Desk, and we will send you Banking Details. Apparently, when a Trusted Third-Party Escrow Service is used, the transfer goes through different accounts.'},
                {id: 6, header: 'Can I pay with Crypto?', description: 'Fortunately, or unfortunately, NOT. Monoverse is not accepting payments with cryptocurrencies.'},
                {id: 7, header: 'Is the price fixed?', description: 'Yes, but we accept counterproposals. Feel free to reach out Monoverse Help Desk here.'},
                {id: 8, header: 'Do you offer Domain Transfers via a Trusted Third-party Escrow Service?', description: 'Indeed, we do. While we support the use of Escrow.com as the most reliable service provider for Domain Transfers, we are happy to take the process with any Escrow Service of your choice.\n' +
                        'To do so, kindly proceed with a Custom Checkout and we take it from there.'},
                {id: 9, header: 'How long does it take for a Domain Transfer?', description: 'Others being equal, it will take approximately 5-7 business days. Math is the following:\n' +
                        '(1-2 days for clearing the payment) + (1-2 days to issue the EPP code) + (1-2 days to confirm the transfer via Registrar). Please note that transfers processed via Escrow.com or other Trusted Third-party will take much longer.'},
                {id: 10, header: 'Will I get a Tax Invoice for the purchase?', description: 'Absolutely. Following the completion of the Transfer, a formal Tax Invoice will be issued in the name of buyer. Please also note that VAT charges are included in the acquisition price, pursuant to the laws of the United Kingdom.'},
                {id: 9, header: 'How will I gain access to Monoverse Digital Assets and Branding Toolkit?', description: 'Following the completion of the Domain Transfer, we will send you a link to access Monoverse Portfolio of Digital Assets.'},

            ],
            faqsub4: [
                {id: 1, header: 'Can I park and sell my Domains via Monoverse?', description: 'Yes and no. For the very best interest of Monoversians and their digital property, Monoverse shall onboard only structured collections of premium domains. Random holdings and secondary TLDs will not be qualified.'},
                {id: 2, header: 'How it works?', description: "Step 1 : You need to redirect your DNS to ns1.monoverse.com, ns2.monoverse.com.\n" +
                        "Step 2 : You are kindly requested to provide Monoversians with custom content and hashtags for each domain.\n" +
                        "Step 3 : Monoversians will add a mark-up to your ask prices. This is not a commission-based model."},
                {id: 3, header: 'What makes you better than competition?', description: 'Monoverse is not better, but rather using a unique business model to pitch domains for end-users, leveraged by the element of network and branding.'},
            ],




        }),
        methods:{

        }


    }

</script>
