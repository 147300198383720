import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"faqroot"},[_vm._m(0),_c(VRow,{attrs:{"justify":"center"}},[_c(VExpansionPanels,{staticClass:"faqOuter"},_vm._l((_vm.faqsub3),function(item,i){return _c(VExpansionPanel,{key:i,staticStyle:{"margin-bottom":"8px","background-color":"#000","color":"white","border-radius":"15px","border-color":"white"}},[_c(VExpansionPanelHeader,{staticStyle:{"border-radius":"15px"},attrs:{"disable-icon-rotate":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{attrs:{"color":"white"}},[_vm._v(" expand_more ")])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.header)+" ")]),_c(VExpansionPanelContent,[_vm._v(" "+_vm._s(item.description)+" ")])],1)}),1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"faqHeader"},[_c('h2',[_vm._v("Let's answer some of your questions")])])}]

export { render, staticRenderFns }